import PoolsBody1 from './PoolsBody1'
import PoolsBody2 from './PoolsBody2'
import PoolsBody3 from './PoolsBody3'
import PoolsBody4 from './PoolsBody4'

export const PoolsBodies = {
  PoolsBody1,
  PoolsBody2,
  PoolsBody3,
  PoolsBody4,
}
