import React from 'react'
import QuestSlide1 from './QuestSlide1'
import QuestSlide2 from './QuestSlide2'
import QuestSlide3 from './QuestSlide3'
import QuestSlide4 from './QuestSlide4'

export const QuestSlides = [
  <QuestSlide1 key="quest1" />,
  <QuestSlide2 key="quest2" />,
  <QuestSlide3 key="quest3" />,
  <QuestSlide4 key="quest4" />,
]
