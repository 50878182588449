import LendingBody1 from './LendingBody1'
import LendingBody2 from './LendingBody2'
import LendingBody3 from './LendingBody3'
import LendingBody4 from './LendingBody4'
import LendingBody5 from './LendingBody5'

export const LendingBodies = {
  LendingBody1,
  LendingBody2,
  LendingBody3,
  LendingBody4,
  LendingBody5,
}
