import FarmsBody1 from './FarmsBody1'
import FarmsBody2 from './FarmsBody2'
import FarmsBody3 from './FarmsBody3'
import FarmsBody4 from './FarmsBody4'

export const FarmsBodies = {
  FarmsBody1,
  FarmsBody2,
  FarmsBody3,
  FarmsBody4,
}
