import * as React from 'react'
import { useTheme } from 'styled-components'

const Icon: React.FC<{ fill?: string; color?: string }> = (props) => {
  const theme = useTheme()
  return (
    <svg width="25" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={theme.colors.text}
        d="M4.80642 1.76652C4.73515 2.13792 4.69688 2.74169 4.69688 3.40855C4.69688 4.04386 4.73515 4.62253 4.79322 4.99972C4.79982 5.00616 4.87108 5.42712 4.94829 5.57195C5.09016 5.83651 5.3673 6 5.66424 6H5.68998L5.74238 5.9953C5.94814 5.9674 6.28914 5.82953 6.28914 5.82363C6.97276 5.54685 8.32087 4.68561 8.86197 4.11273L8.88692 4.08827C8.93307 4.04253 9.03155 3.94248 9.08104 3.87393C9.18464 3.74197 9.23611 3.57848 9.23611 3.41434C9.23611 3.23218 9.17804 3.06289 9.06851 2.92386C9.04277 2.89875 8.94577 2.7919 8.85603 2.70372C8.32681 2.1508 6.94703 1.24449 6.22513 0.968356C6.11559 0.923942 5.83779 0.829322 5.68998 0.823529C5.54811 0.823529 5.41217 0.854425 5.2835 0.917506C5.12249 1.00569 4.99382 1.14408 4.92255 1.30758C4.87702 1.42086 4.80642 1.76073 4.80642 1.76652Z"
      />
      <path
        fill={theme.colors.text}
        d="M0.602436 2.7602C0.263883 2.7937 0 3.0723 0 3.41144C0 3.77319 0.30024 4.06671 0.671086 4.06671L3.21488 3.9251L3.27632 3.92165C3.53833 3.892 3.74145 3.67503 3.74145 3.41144C3.74145 3.12758 3.50588 2.89779 3.21488 2.89779L0.671086 2.75682L0.602436 2.7602Z"
      />
    </svg>
  )
}

export default Icon
